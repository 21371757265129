import React, { useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  Box,
  Image,
  Text,
  Flex,
  useMediaQuery,
  Button,
  VStack,
  Img,
  Show,
} from "@chakra-ui/react";
import { PageLoading } from "@karkhanaui/react";
import { useDispatch, useSelector } from "react-redux";
import PageLoaderURL from "../img/loader.gif";
import img1 from "../img/img1.svg";
import img2 from "../img/img2.svg";
import img3 from "../img/img3.svg";
import bob from "../img/bob.svg";
import book from "../img/book.svg";
import man from "../img/man.svg";
import KarkhanaLogo from "../img/Kkonnect.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "./swiperButton.css";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import "swiper/swiper.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";

export const PublicLayout: React.FC<any> = ({ Component, ...rest }) => {
  const [pageLoadingData] = useSelector((state: any) => [state.pageLoading]);
  const [isVisible,setIsVisible] =  React.useState<boolean>(false)
  const textArray = [
    "All-in-one platform to manage RFQs, Items, and Suppliers",
    "Effortlessly send RFQs to over 20+ suppliers",
    "Compare multiple quotations simultaneously",
  ];
  const handleVisible = ()=>{
    setIsVisible(true)
  }
  const [cur, setCur] = useState<number>(0);
  return (
    <React.Fragment>
      <Show above="md">
        <Flex
          justifyContent={"space-between"}
          bg="linear-gradient(0deg, rgba(0, 135, 238, 0.03), rgba(0, 135, 238, 0.03)), #FFFFFF"
          minH="100vh"
          zIndex={10}
        >
          <VStack
            alignItems="center"
            justifyContent="center"
            gap="1em"
            pl="10"
            width="40%"
          >
            <Image
              src={KarkhanaLogo}
              width="300px"
              mb="3em"
              alt="Karkhana logo"
              height="auto"
            />
            <Box width="400px" height="60vh" mx="auto">
              <Swiper
                spaceBetween={2}
                slidesPerView={1}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                autoplay={{ delay: 1500 }}
                loop={true}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <Img src={img1} alt="Image 1" width="70%" mx="auto" />
                </SwiperSlide>
                <SwiperSlide>
                  <Box>
                    <Img
                      src={img2}
                      alt="Image 2"
                      width="70%"
                      mb="3em"
                      mx="auto"
                    />
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Img
                    src={img3}
                    alt="Image 3"
                    width="80%"
                    mx="auto"
                    my="auto"
                  />
                </SwiperSlide>
              </Swiper>
            </Box>
          </VStack>

          <Box
            width="50%"
            h="inherit"
            bg="#fff"
            borderRadius={"md"}
            boxShadow={"lg"}
            m="2em"
          >
            <Component {...rest} />
          </Box>
          {pageLoadingData?.loading && (
            <PageLoading
              pageLoading={pageLoadingData}
              pageLoaderURL={PageLoaderURL}
            />
          )}
        </Flex>
      </Show>

      {/* mobile screens */}
      <Show below="md">
       {!isVisible ? (
        <Box
        width="100%"
        height={{ base: "100dvh", lg: "100vh" }}
        className="ob-main-container"
        bg="#0087ee20"
      >
        <Flex flexDir="column" w="100%" h="100dvh">
          <Button
            variant="link"
            onClick={handleVisible}
            ml="auto"
            mt="1rem"
            mr="1rem"
          >
            Skip
          </Button>
          <Box
            width="100%"
            height="55%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              spaceBetween={2}
              slidesPerView={1}
              autoplay={{ delay: 1500 }}
              loop={true}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              // onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => {
                setCur((prev) => (prev + 1) % 3);
              }}
            >
              <SwiperSlide>
                <Flex alignItems="center" justifyContent="center" mb="2rem">
                  <Img src={bob} />
                </Flex>
              </SwiperSlide>
              <SwiperSlide>
                <Flex alignItems="center" justifyContent="center" mb="2rem">
                  <Img src={book} />
                </Flex>
              </SwiperSlide>
              <SwiperSlide>
                <Flex alignItems="center" justifyContent="center" mb="2rem">
                  <Img src={man} />
                </Flex>
              </SwiperSlide>
            </Swiper>
          </Box>
          <Box
            borderRadius="1rem 1rem 0 0"
            p="3rem 1.5rem"
            gap="1.5rem"
            background="#fff"
            display="flex"
            flexGrow={1}
            flexDir="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              fontSize="24px"
              color="#0087ee"
              fontWeight="semibold"
              textAlign="center"
              width="75%"
            >
              {textArray[cur]}
            </Text>
            <Button
              borderRadius="0.75rem"
              width="100%"
              bgColor="#0087ee"
              _hover={{ bgColor: "#0087ee80" }}
              color="#fff"
              h="50px"
              onClick={handleVisible}
            >
              Get Started
            </Button>
          </Box>
        </Flex>
      </Box>
       ):(
        <Box>
        <Component {...rest} />
      </Box>
       )} 
      </Show>
    </React.Fragment>
  );
};
