import React from "react";
import { createContext, useState } from "react";
interface FormDataContextType {
  formData: any;
  setFormData: (data: any) => void;
  categoryValue: any;
  setCategoryValue: (value: any) => void;
  isItemsLibrary: any;
  setIsItemsLibrary: (value: any) => void;
  mailId: any;
  setMailId: (value: any) => void;
  isSupplierTab: any;
  setIsSupplierTab: (value: any) => void;
  stepsForm: any;
  setSteps: (value: any) => void;
  quoteIdTab: any;
  setQuoteTab: (value: any) => void;
  rfqName : any;
  setRfqName : (vale :any) => void;
 
}
export const FormDataContext = createContext<FormDataContextType>({
  formData: null,
  setFormData: () => {},
  categoryValue: null,
  setCategoryValue: () => {},
  isItemsLibrary: null,
  setIsItemsLibrary: () => {},
  mailId: null,
  setMailId: () => {},
  isSupplierTab: null,
  setIsSupplierTab: () => {},
  stepsForm: null,
  setSteps: () => {},
  quoteIdTab: null,
  setQuoteTab: () => {},
  rfqName: null,
  setRfqName: ()=>{}

  
});

export const FormDataProvider = ({ children }: any) => {
  const [formData, setFormData] = useState();
  const [categoryValue, setCategoryValue] = React.useState();
  const [isItemsLibrary, setIsItemsLibrary] = React.useState(false);
  const [mailId, setMailId] = useState();
  const [isSupplierTab, setIsSupplierTab] = React.useState<boolean>(true);
  const [stepsForm, setSteps] = React.useState<any>(0);
  const [quoteIdTab, setQuoteTab] = React.useState<any[]>([]);
  const [rfqName, setRfqName] = React.useState<any>();
  return (
    <FormDataContext.Provider
      value={{
        formData,
        setFormData,
        categoryValue,
        setCategoryValue,
        isItemsLibrary,
        setIsItemsLibrary,
        mailId,
        setMailId,
        isSupplierTab,
        setIsSupplierTab,
        stepsForm,
        setSteps,
        quoteIdTab,
        setQuoteTab,
        rfqName,
        setRfqName
       
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
};
