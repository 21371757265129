import { Box, Text } from "@chakra-ui/react";
import React from "react"

const AddressCardSupplier = (content:any, ...props:any) => {
    console.log("content",content)
  return (
    <Box p="1em" borderRadius={"md"} boxShadow={"inherit"} border={"1px solid #0087ee70"}>
      <Text fontSize="18px" color={"black"}>{content.content.address_title}</Text>
      <Text fontSize="14px">
        {content.content.address_line_1}, &nbsp;{" "}
        {content.content.address_line_2 ? content.address_line_1 : ""}
      </Text>
      <Text fontSize="14px">{content.content.city} ,{content.content.state}-{content.content.pincode}</Text>
      <Text fontSize="14px">{content.content.country}</Text>
    </Box>
  );
};

export default AddressCardSupplier;
