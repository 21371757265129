import React from "react";
import { createContext, useState } from "react";
interface FormDataContextType {
    valueForm: any;
    setValueForm : (data: any) => void;
    addItem : any;
    setAddItem : (data : any) => void;
    addLibrary : any;
    setAddLibrary : (data:any)=>void;
    ammendData : any;
    setAmmendData : (data:any)=>void;
    ammendPo : any;
    setAmmendPos : (data:any)=>void;
    supplierId : any;
    setSupplierId : (data:any)=>void;
    isManualPo :  any;
    setIsManualPo : (data:any) => void;
}
export const PurchaseOrderContext = createContext<FormDataContextType>({
    valueForm: null,
    setValueForm: () => {},
    addItem:null,
    setAddItem : ()=>{},
    addLibrary:null,
    setAddLibrary : () => {},
    ammendData : null,
    setAmmendData : ()=>{},
    ammendPo : null,
    setAmmendPos : ()=>{},
    supplierId : null,
    setSupplierId : ()=>{},
    isManualPo : null,
    setIsManualPo : () =>{}
})
export const PurchaseOrderProvider = ({ children }: any) => {
    const [valueForm, setValueForm] = useState();
    const [addItem, setAddItem] =  useState();
    const [addLibrary , setAddLibrary] = useState();
    const [ammendData, setAmmendData] = useState([])
    const [ammendPo , setAmmendPos] =  useState([])
    const [supplierId , setSupplierId] =  React.useState<any>()

    return (
        <PurchaseOrderContext.Provider
          value={{
            valueForm,
            setValueForm,
            addItem, 
            setAddItem,
            addLibrary,
            setAddLibrary,
            ammendData, setAmmendData,
            ammendPo, setAmmendPos,
            supplierId,
            setSupplierId

          }}
          >
            {children}
          </PurchaseOrderContext.Provider>
    )
}