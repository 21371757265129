import { Box , Button, Img , Text} from '@chakra-ui/react'
import React from 'react'
import DeleteNDAImg from '../../img/DeleteNDA.svg'
interface deleteProps{
    deleteFunction : ()=> void;
}
const DeleteNDA = ({deleteFunction}:deleteProps) => {
  return (
    <Box width = "100%" height="100%" display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} p="1em">
    <Img src = {DeleteNDAImg}  width="264.22px" height={"235.95px"} mt="1em" mb ="1em"/>
    <Text fontSize={"16px"} fontWeight={500} lineHeight={"18.96px"}>Are you sure you want to delete this file?</Text>
    <Box display={"flex"} flexDir={"row"} gap="1em" mt="1em" mb="1em">
        <Button children="Cancel" border="1px solid #000" color="#000" bg="#fff" _hover={{bg:"#fff80"}} />
        <Button children  = "Delete" color="#fff" bg="rgba(239, 111, 108, 1)" _hover={{bg:"rgba(239, 111, 108, 0.8)"}} onClick={deleteFunction}/>
    </Box>
        
    </Box>
  )
}

export default DeleteNDA