import {
  Flex,
  Image,
  Box,
  FlexProps,
  Stack,
  Text,
  Menu,
  Tag,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
  useColorMode,
  useMediaQuery,
  // Button,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

import { GrHomeRounded } from "react-icons/gr";
import {
  BsFolder2Open,
  BsBell,
  BsColumnsGap,
  BsFileEarmarkText,
  BsTruck,
  BsChevronDown,
  BsGear,
  BsQuestionCircle,
  BsPeople,
  BsCardText,
  BsGraphUpArrow,
} from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import smallLogo from "../src/img/smallLogo.webp";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  AppDispatch,
  PageLoading,
  NotificationCard,
  getUserDetailsProcure,
  Overlay,
} from "@karkhanaui/react";
import { FiLogOut } from "react-icons/fi";
import { ProductFruits } from "react-product-fruits";
import Cookies from "js-cookie";
import { datadogRum } from "@datadog/browser-rum";
import { CloseIcon } from "@chakra-ui/icons";

interface IconTextProps extends FlexProps {
  Icon: typeof GrHomeRounded;
  text: string;
  active?: boolean;
  link: string;
  disabled: boolean;
}

interface IconMiniProps extends FlexProps {
  Icon: typeof GrHomeRounded;
  active?: boolean;
  link: string;
  text: string;
}
const IconText = ({
  Icon,
  text,
  link,
  disabled,
  active = false,
  ...rest
}: IconTextProps) => {
  const width = window.innerWidth;

  if (active) {
    return (
      <Flex
        justifyContent="start"
        alignItems="center"
        {...rest}
        width="100%"
        bg={"rgba(0, 135, 238, 0.1)"}
        borderRadius="md"
        as={Link}
        to={link}
      >
        <Icon size="20px" color="#0087ee" />
        <Box ml="11px">
          <Text
            textStyle="primary.text"
            fontWeight={"700"}
            fontSize="14px"
            color="#0087ee"
          >
            {text}
          </Text>
        </Box>
      </Flex>
    );
  }

  if (disabled) {
    return (
      <Flex
        justifyContent="start"
        alignItems="center"
        {...rest}
        borderRadius={"10px"}
      >
        <Icon size="20px" />
        <Box ml="11px">
          <Text textStyle="primary.text" fontSize="14px">
            {text}
          </Text>
        </Box>
      </Flex>
    );
  }

  return (
    <Link to={link}>
      <Flex
        justifyContent="start"
        alignItems="center"
        {...rest}
        borderRadius={"10px"}
        width="100%"
      >
        <Icon size="20px" />
        <Box ml="11px">
          <Text textStyle="primary.text" fontSize="14px">
            {text}
          </Text>
        </Box>
      </Flex>
    </Link>
  );
};

const IconMini = ({
  Icon,
  link,
  text,
  active = false,
  ...rest
}: IconMiniProps) => {
  if (active) {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="0.3em"
        {...rest}
        bg={"blue.50"}
        ml="0"
        as={Link}
        to={link}
        p="0.6em"
        borderTopRightRadius="md"
        borderBottomRightRadius="md"
        width="100%"
        borderLeft="4px solid #0087ee"
      >
        <Icon size="26px" color="#0087ee" />
        <Text fontSize="11px" fontWeight={"700"} color="#0087ee">
          {text}
        </Text>
      </Flex>
    );
  }
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="0.3em"
      {...rest}
      borderRadius={"10px"}
      ml="0"
      p="0.6em"
      as={Link}
      to={link}
      width="100%"
    >
      <Icon size="26px" />
      <Text fontSize="11px">{text}</Text>
    </Flex>
  );
};

export const AppShell = ({
  children: Component,
  imageURL,
  pageLoaderURL,
  notifications,
  bread,
  navigateToDoc,
}: {
  children?: React.LazyExoticComponent<React.FC<any>>;
  imageURL: string;
  pageLoaderURL: string;
  notifications: any[];
  bread: any;
  navigateToDoc: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  let pathname = useLocation()?.pathname.split("/");
  const navigate = useNavigate();
  const [pageLoadingData, userData, authState] = useSelector((state: any) => [
    state.pageLoading,
    state.getUser.customer,
    state.authProcure,
  ]);
  const { colorMode, toggleColorMode } = useColorMode();

  const [activeTab, setActiveTab] = React.useState<String>("/" + pathname[1]);
  const [isExpanded, setExpanded] = React.useState(false);
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isMobileTablet] = useMediaQuery("(max-width: 768px)");
  const [collapsed, setCollapsed] = React.useState(true);
  const [menu, setMenu] = React.useState(false);
  const [isAnalyticsMenuOpen, setIsAnalyticsMenuOpen] =
    React.useState<boolean>(false);
  const [analyticsMenuPosition, setAnalyticsMenuPosition] = React.useState<any>(
    { top: 0, left: 0 }
  );
  const menuRef1 = useRef();
  const menuRef2 = useRef();

  const getAnalyticsMenuPosition = (event: any) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setAnalyticsMenuPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    window.dataLayer.push({
      event: "Pageview",
      page_url: window.location.href,
      page_name: window.location.pathname,
      user_id: authState?.uniqueUserId,
      organization_name: userData?.company_name,
      // userEmail: String(Cookies.get("user_id")),
    });
  }, [window.location]);

  useEffect(() => {
    setActiveTabBackground();
    let title = pathname[1];
    document.title =
      pathname[1].charAt(0).toUpperCase() + title.toString().slice(1);
  }, [pathname[1]]);

  useEffect(() => {
    if (isMobile || isMobileTablet) {
      setCollapsed(true);
    }
  }, [isMobileTablet, isMobile]);

  const setActiveTabBackground = () => {
    // setActiveTab("/" + pathname[1]);
    switch (pathname[1]) {
      case "home":
        setActiveTab("/home");
        break;
      case "rfqmain":
      case "rfqpreview":
        setActiveTab("/rfqmain");
        break;
      case "supplier":
      case "supplierrecords":
        setActiveTab("/supplier");
        break;
      case "items":
      case "item":
        setActiveTab("/items");
        break;
      case "profile":
        setActiveTab("/profile");
        break;
      case "purchasepage":
      case "createpo":
      case "addpurchase":
      case "trackorder":
      case "purchasesummary":
        setActiveTab("/purchasepage");
        break;
      case "buyerAnalytics":
      case "supplierAnalytics":
        setActiveTab("/buyerAnalytics");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getUserDetailsProcure());
  }, [dispatch]);

  const menuItems = [
    {
      icon: BsColumnsGap,
      text: "Home",
      sideBarText: "Home",
      link: "/home",
    },
    {
      icon: BsFileEarmarkText,
      text: "RFQ List",
      sideBarText: "RFQ",
      link: "/rfqmain",
    },
    {
      icon: BsTruck,
      sideBarText: "PO",
      text: "Purchase Order",
      link: "/purchasepage",
    },
    {
      icon: BsGraphUpArrow,
      text: "Analytics",
      sideBarText: "Analytics",
      link: "/buyerAnalytics",
    },
    {
      icon: BsPeople,
      text: "Suppliers",
      sideBarText: "Supplier",
      link: "/supplier",
    },

    {
      icon: BsFolder2Open,
      text: "Items Library",
      sideBarText: "Items",
      link: "/items",
    },
    {
      icon: BsCardText,
      text: "Contracts",
      sideBarText: "Contracts",
      link: "/comingsoonC",
    },
  ];

  const handleSignout = () => {
    window.dataLayer.push({
      event: "User Signout",
      page_url: window.location.href,
      page_exit: window.location.pathname,
      user_id: authState?.uniqueUserId,
      // userEmail: String(Cookies.get("user_id")),
    });
    //stop datadogrecording
    datadogRum.stopSessionReplayRecording();
    datadogRum.stopSession();
    datadogRum.clearUser();
    dispatch(logout());
  };

  const handleSearchClick = () => {
    setExpanded(true);
  };

  const handleCloseClick = () => {
    setExpanded(false);
  };
  const variants = {
    open: { opacity: 1, x: "0" },
    closed: { opacity: 1, x: "0" },
  };

  return (
    <Flex bg="#f3f6f9" height="100vh" overflow="hidden">
      {/* Sidebar */}
      {!(collapsed && isMobileTablet) && (
        <Box
          w={
            collapsed
              ? isMobileTablet
                ? "0%"
                : "7%"
              : isMobileTablet
              ? "auto"
              : "20%"
          }
          as={motion.div}
          display="flex"
          flexShrink="0"
          zIndex={1000}
          h="100%"
          position={isMobileTablet ? "absolute" : "relative"}
          flexDirection="column"
          justifyContent="space-between"
          animate={{ x: "0" }}
          transition={{ type: "inertia" }}
          variants={variants}
          initial={{ x: "-100%" }}
          exit={{ x: "-100%" }}
          bg="#fff"
          overflow={"auto"}
        >
          <motion.div
            animate={collapsed ? "closed" : "open"}
            variants={variants}
          >
            <Flex
              flexDirection="column"
              w="100%"
              mt="10px"
              justifyContent="space-between"
            >
              {collapsed ? (
                <Flex flexDirection="column" width="100%" alignItems="center">
                  <Image
                    objectFit={"contain"}
                    w="50px"
                    h="50px"
                    src={smallLogo}
                    m="0.8rem 0 2rem 0"
                    cursor="pointer"
                    alt="Procurement Portal"
                    onClick={() => navigate("/home")}
                  />

                  {menuItems.map((item, index) => {
                    return (
                      <motion.div
                        key={index}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Box
                          position={
                            item.text == "Analytics" ? "relative" : "static"
                          }
                          p="0"
                          m="0"
                        >
                          <IconMini
                            key={item.text}
                            mt="1em"
                            mx="auto"
                            Icon={item.icon}
                            alignItems="center"
                            active={activeTab === item.link ? true : false}
                            _hover={{ color: "#0087ee" }}
                            ref={item.text == "Analytics" ? menuRef1 : null}
                            link={
                              item.link
                                ? item.text != "Analytics" && item.link
                                : "#"
                            }
                            onClick={(e) => {
                              if (item.text == "Analytics") {
                                getAnalyticsMenuPosition(e);
                                setIsAnalyticsMenuOpen(!isAnalyticsMenuOpen);
                              } else setActiveTab(item.link);
                            }}
                            text={item.sideBarText}
                          />
                          {item.text === "Analytics" && (
                            <Box
                              mt="0.3em"
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              width={"100%"}
                            >
                              <Tag
                                variant="subtle"
                                bg="rgba(0, 135, 238, 0.1)"
                                color="#0087ee"
                                fontSize="10px"
                                mb="1rem"
                              >
                                Beta
                              </Tag>
                            </Box>
                          )}
                        </Box>
                      </motion.div>
                    );
                  })}
                </Flex>
              ) : (
                // don't change the code here
                <Box padding="0 0.5rem">
                  <Box display={"flex"} justifyContent={"space-evenly"}>
                    <Image
                      w="60%"
                      src={imageURL}
                      mb="3rem"
                      mt=".8rem"
                      mx="auto"
                      cursor="pointer"
                      onClick={() => navigate("/home")}
                    />
                    {isMobileTablet && (
                    <Box
                    mr={"0.5rem"}
                     onClick={() => setCollapsed(!collapsed)}>
                      <CloseIcon />
                    </Box>
                    )}
                  </Box>
                  {isMobileTablet && (
                    <Flex
                      mb="1.5rem"
                      ml="1rem"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap="1.25rem"
                    >
                      <Box
                        borderRadius="50%"
                        bg="rgba(0, 135, 238, 0.1)"
                        color="rgba(0, 135, 238, 1)"
                        width="4.5rem"
                        height="4.5rem"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize="2.5rem" fontWeight="bold">
                          {Cookies.get("full_name")?.slice(0, 1).toUpperCase()}
                        </Text>
                      </Box>
                      <Flex flexDir="column">
                        <Text
                          textStyle="primary.contentTitle"
                          fontWeight="extrabold"
                          wordBreak="break-word"
                        >
                          {Cookies.get("full_name")}
                        </Text>
                        {/* <Text textStyle="primary.text" wordBreak="break-word">
														Purchase Manager
													</Text> */}
                        {/* <Text
														as={Link}
														to="/profile"
														textStyle="primary.small"
														textColor="#4BA18A"
													>
														View Profile
													</Text> */}
                        <Text
                          textStyle="primary.small"
                          textColor="rgba(0, 135, 238, 1)"
                          onClick={handleSignout}
                          cursor="pointer"
                        >
                          Sign Out
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                  {menuItems.map((item, index) => (
                    <Box key={index}>
                      <motion.div>
                        <Box
                          position={item.text == "Analytics" ? "relative" : ""}
                          p="0"
                          m="0"
                        >
                          <Flex align="center">
                            {index === 5 ? (
                              <IconText
                                key={item.text}
                                Icon={item.icon}
                                text={item.text}
                                fontSize="14px"
                                p="1rem 0.5rem"
                                mb="1rem"
                                // active={activeTab === item.link}
                                _hover={{ color: "#0087ee" }}
                                ref={item.text == "Analytics" ? menuRef2 : null}
                                link={
                                  item.link
                                    ? item.text != "Analytics" && item.link
                                    : "#"
                                }
                                onClick={(e) => {
                                  if (item.text == "Analytics") {
                                    getAnalyticsMenuPosition(e);
                                    setIsAnalyticsMenuOpen(
                                      !isAnalyticsMenuOpen
                                    );
                                  } else {
                                    setActiveTab(item.link);
                                    if (isMobileTablet) setCollapsed(true);
                                  }
                                }}
                                // disabled={true} // Disable index 2 and 5
                              />
                            ) : (
                              <IconText
                                key={item.text}
                                Icon={item.icon}
                                text={item.text}
                                fontSize="14px"
                                p="1rem 0.5rem"
                                mb="1rem"
                                active={activeTab === item.link}
                                _hover={{ color: "#0087ee" }}
                                ref={item.text == "Analytics" ? menuRef2 : null}
                                link={
                                  item.link
                                    ? item.text != "Analytics" && item.link
                                    : "#"
                                }
                                onClick={(e) => {
                                  if (item.text == "Analytics") {
                                    getAnalyticsMenuPosition(e);
                                    setIsAnalyticsMenuOpen(
                                      !isAnalyticsMenuOpen
                                    );
                                  } else {
                                    setActiveTab(item.link);
                                    if (isMobileTablet) setCollapsed(true);
                                  }
                                }}
                              />
                            )}

                            {index === 5 && (
                              <Tag
                                ml="0.1em"
                                variant="subtle"
                                bg="rgba(0, 135, 238, 0.1)"
                                color="#0087ee"
                                fontSize="10px"
                                mb="1rem"
                              >
                                Coming Soon
                              </Tag>
                            )}
                            {item.text === "Analytics" && (
                              <Tag
                                mt="0.3em"
                                variant="subtle"
                                bg="rgba(0, 135, 238, 0.1)"
                                color="#0087ee"
                                fontSize="10px"
                                mb="1rem"
                              >
                                Beta
                              </Tag>
                            )}
                          </Flex>
                        </Box>
                      </motion.div>
                    </Box>
                  ))}
                </Box>
              )}
            </Flex>
          </motion.div>
          {/* <IconButton
			aria-label={collapsed ? "Expand sidebar" : "Collapse sidebar"}
			icon={collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
			onClick={toggleCollapsed}
			/> */}
        </Box>
      )}

      <Flex
        direction="column"
        w={
          collapsed
            ? isMobileTablet
              ? "100%"
              : "93%"
            : isMobileTablet
            ? "100%"
            : "80%"
        }
      >
        <Box h="65px" bg="white" marginBottom="2px" flexShrink={0}>
          <Flex
            h="100%"
            alignItems="center"
            justifyContent="space-between"
            padding="10px"
            position="relative"
            zIndex={999}
            // border={"2px solid green"}
          >
            <Flex>
              {!isMobileTablet ? (
                <motion.div
                  initial={{ scale: 1 }}
                  animate={{ rotateY: !collapsed ? 180 : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <IconButton
                    icon={<MdKeyboardDoubleArrowRight size={"100%"} />}
                    boxSize={6}
                    background={"none"}
                    _hover={{ color: "#0087ee" }}
                    _active={{ backgroundColor: "transparent" }}
                    aria-label={
                      collapsed ? "Expand sidebar" : "Collapse sidebar"
                    }
                    onClick={toggleCollapsed}
                  />
                </motion.div>
              ) : (
                <motion.div
                  initial={{ scale: 1 }}
                  animate={{ rotateY: !collapsed ? 180 : 0 }}
                  transition={{ duration: 0.5 }}
                  style={{ marginRight: "0.8rem" }}
                >
                  {collapsed && (
                    <AiOutlineMenu
                      size="1.5rem"
                      onClick={toggleCollapsed}
                      cursor="pointer"
                      style={{ color: "#0087ee" }}
                    />
                  )}
                </motion.div>
              )}

              {bread}
            </Flex>
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              px="2em"
              gap="1.5em"
            >
              {/* <Button
                onClick={() => toggleColorMode()}
                children={colorMode === "dark" ? <BiMoon /> : <BsSun />}
                variant={"link"}
                mr="1em"
              /> */}

              <Stack spacing="6" direction="row" alignItems="center">
                {/* <BsSearch size="20px" cursor="pointer" /> */}
                {/* <Box>
                  <motion.div
                    initial={{ width: '30px' }}
                    animate={{ width: isExpanded ? '250px' : '30px' }}
                    exit={{ width: '30px' }}
                    transition={{ duration: 0.3 }}
                  >
                    
                    {isExpanded ? (
                      <InputGroup>
                      <InputRightElement>
                          <IconButton
                              icon={<CloseIcon  boxSize={2} color="gray.600"/>}
                              variant="unstyled"
                              size="sm"
                              onClick={handleCloseClick}
                              aria-label={""}
                              />
                      </InputRightElement>
                      <InputLeftElement ml="0.3em">
                      <IconButton
                          icon={<BsSearch size={18} color="gray"/>}
                          variant="unstyled"
                          size="sm"
                          onClick={handleCloseClick}
                          aria-label={""}
                          />
                    </InputLeftElement>
                    <Input bgColor="gray.100" placeholder="Search" />
                    </InputGroup>
                        ) : (
                          <InputGroup>
                          <IconButton
                            icon={<BsSearch size={20}/>}
                            variant="unstyled"
                            size="sm"
                            onClick={handleSearchClick}
                            aria-label={""}
                          />
                          </InputGroup>
                        )}
                    
                  </motion.div>
              </Box> */}
                <Popover isLazy size="4xl" variant="responsive">
                  <PopoverTrigger>
                    <span role="button" aria-label="Notifications" tabIndex="0">
                      <BsBell size="20px" cursor="pointer" />
                    </span>
                  </PopoverTrigger>
                  <PopoverContent position="relative" zIndex={99999}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody p="0px">
                      <NotificationCard
                        notificationList={notifications}
                        navigate={navigateToDoc}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>

              {!isMobileTablet && (
                <Box>
                  <Stack direction="row" alignItems="center" gap="0.6em">
                    <Box textAlign={"right"}>
                      <Text
                        fontWeight="normal"
                        fontSize="14px"
                        fontFamily="Rubik"
                      >
                        {userData?.first_name}
                      </Text>
                    </Box>
                    <Menu
                      isLazy={true}
                      closeOnBlur={false}
                      onClose={() => {
                        setMenu(false);
                      }}
                    >
                      <MenuButton
                        transition="all 0.2s"
                        onClick={() => {
                          setMenu(!menu);
                        }}
                      >
                        <Flex alignItems="center" gap="0.4em">
                          <Flex
                            rounded="full"
                            width="26px"
                            height="26px"
                            bg="#0087ee30"
                            fontWeight="bold"
                            alignItems="center"
                            justifyContent="center"
                            fontFamily="Rubik"
                            color="#0087ee"
                          >
                            {userData
                              ? userData?.first_name?.charAt(0).toUpperCase()
                              : ""}
                          </Flex>
                          <motion.div
                            initial={{ scale: 1 }}
                            animate={{ rotateX: menu ? 180 : 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <BsChevronDown size="12" />
                          </motion.div>
                        </Flex>
                      </MenuButton>
                      <MenuList zIndex={9999} pt="0" boxShadow="lg">
                        <MenuItem
                          marginTop="0"
                          padding="1em"
                          backgroundColor="#0087ee20"
                          borderRadius="md"
                        >
                          <Flex gap="1.5em" alignItems="center">
                            <Flex
                              rounded="20%"
                              width="50px"
                              height="50px"
                              bg="#0087ee"
                              fontWeight="bold"
                              alignItems="center"
                              justifyContent="center"
                              fontFamily="Rubik"
                              color="white"
                            >
                              {userData?.first_name?.charAt(0).toUpperCase()}
                            </Flex>
                            <Stack>
                              <Text fontWeight="bold" fontFamily="Rubik" mb="0">
                                {userData?.first_name}
                              </Text>
                              <Text
                                fontFamily="Rubik"
                                margin="0px"
                                fontSize="12px"
                              >
                                {userData?.email}
                              </Text>
                            </Stack>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          icon={<BsGear color="#0087ee" size="25" />}
                          onClick={() => {
                            setActiveTab("");
                            navigate("/profile");
                          }}
                          marginTop="0.6em"
                          borderRadius="md"
                        >
                          <Text
                            fontWeight="semibold"
                            fontSize="14px"
                            fontFamily="Rubik"
                          >
                            Settings
                          </Text>
                        </MenuItem>
                        <MenuItem
                          icon={<BsQuestionCircle color="#0087ee" size="25" />}
                          onClick={() => {
                            setActiveTab("");
                            navigate("/help");
                          }}
                          borderRadius="md"
                        >
                          <Text
                            fontWeight="semibold"
                            fontSize="14px"
                            fontFamily="Rubik"
                          >
                            Help
                          </Text>
                        </MenuItem>

                        <MenuItem
                          icon={<FiLogOut color="#0087ee" size="25" />}
                          onClick={handleSignout}
                          borderRadius="md"
                        >
                          <Text
                            fontWeight="semibold"
                            fontSize="14px"
                            fontFamily="Rubik"
                          >
                            Sign Out
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Stack>
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>
        <Flex bg="#f3f6f9" flexGrow="1" overflow={"scroll"}>
          <Box width="100%" height="100%">
            {!!Component && <Component />}
          </Box>
        </Flex>
      </Flex>

      {/* Single Menu component for analytics*/}
      <Menu
        isOpen={isAnalyticsMenuOpen}
        placement="right"
        onClose={() => setIsAnalyticsMenuOpen(false)}
      >
        <MenuList
          position="absolute"
          top={analyticsMenuPosition?.top}
          left={analyticsMenuPosition?.left}
          zIndex="9999"
        >
          <MenuItem as="a" href="/buyerAnalytics">
            Buyers
          </MenuItem>
          <MenuItem as="a" href="/supplierAnalytics">
            Suppliers
          </MenuItem>
        </MenuList>
      </Menu>

      {pageLoadingData?.loading && (
        <PageLoading
          pageLoading={pageLoadingData}
          pageLoaderURL={pageLoaderURL}
        />
      )}
      <ProductFruits
        workspaceCode={
          process.env.REACT_APP_NODE_ENV === "production"
            ? "kVr1RCkfgwaj7ugf"
            : "mnTPuf54Mvy1gCPA"
        }
        language="en"
        user={{
          username: String(Cookies.get("user_id")),
          email: String(Cookies.get("user_id")),
          firstname: String(Cookies.get("full_name")),
        }}
      />
    </Flex>
  );
};
