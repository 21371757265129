import React, {  useEffect } from "react";
import {
  Box,
  Text,
  Checkbox,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import {
  GeneralTable,
} from "@karkhanaui/react";
interface data {
  select: any;
  contract_title: any;
  status: any;
  supplier: any;
  contractType: any;
  createdBy: any;
  createdOn: any;
}
import { useNavigate } from "react-router-dom";


const ContractsTable = () => {
    // need to remove this, currently using dummy data

    const dummyData = [
        {
          contract_title: 'Contract 1',
          status: 'draft',
          supplier: 'Supplier A',
          contractType: 'Type A',
          createdBy: 'User 1',
          createdOn: '2022-01-01'
        },
        {
          contract_title: 'Zebra',
          status: 'submitted',
          supplier: 'Supplier B',
          contractType: 'Type B',
          createdBy: 'User 2',
          createdOn: '2022-02-01'
        },
        {
          contract_title: 'Contract 3',
          status: 'draft',
          supplier: 'Supplier C',
          contractType: 'Type C',
          createdBy: 'User 3',
          createdOn: '2022-03-01'
        },
        {
          contract_title: 'Contract 4',
          status: 'submitted',
          supplier: 'Supplier D',
          contractType: 'Type D',
          createdBy: 'User 4',
          createdOn: '2022-04-01'
        },
        {
          contract_title: 'Contract 5',
          status: 'draft',
          supplier: 'Supplier E',
          contractType: 'Type E',
          createdBy: 'User 5',
          createdOn: '2022-05-01'
        },
        {
          contract_title: 'Contract 6',
          status: 'submitted',
          supplier: 'Supplier F',
          contractType: 'Type F',
          createdBy: 'User 6',
          createdOn: '2022-06-01'
        },
        {
          contract_title: 'Contract 7',
          status: 'draft',
          supplier: 'Supplier G',
          contractType: 'Type G',
          createdBy: 'User 7',
          createdOn: '2022-07-01'
        },
        {
          contract_title: 'Contract 8',
          status: 'submitted',
          supplier: 'Supplier H',
          contractType: 'Type H',
          createdBy: 'User 8',
          createdOn: '2022-08-01'
        },
        {
          contract_title: 'Contract 9',
          status: 'draft',
          supplier: 'Supplier I',
          contractType: 'Type I',
          createdBy: 'User 9',
          createdOn: '2022-09-01'
        },
        {
          contract_title: 'Contract 10',
          status: 'submitted',
          supplier: 'Supplier J',
          contractType: 'Type J',
          createdBy: 'User 10',
          createdOn: '2022-10-01'
        }
      ];
      

  const [itemSelected, setItemSelected] = React.useState<boolean>(false);
  const [noOfItemsSelected, setNoOfItemsSelected] = React.useState<number>(0);
  const [selectAllItems, setSelectAllItems] = React.useState<boolean>(false);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [checked , setChecked] = React.useState(false)
  const [tableRowList, setTableRowList] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const selectAllRows = (checked: boolean) => {
    if (checked) {
      setSelectAllItems(true);
      setItemSelected(true);
      setNoOfItemsSelected(tableRowList.length);
    } else {
      setSelectAllItems(false);
      setItemSelected(false);
    }
  };
  const tableHeaderList = [
    { title: <Checkbox colorScheme="blackAlpha"  onClick={()=>selectAllRows}/>, sortable: false },
    { title: "Contract Title", sortable: true },
    { title: "Status", sortable: false },
    { title: "Supplier", sortable: true },
    { title: "Contract Type", sortable: true },
    { title: "Created By", sortable: false },
    { title: "Created On", sortable: true },
   
  ];

// checkbox selection
const handleRowSelection = (rowName: string, checked: boolean) => {
    
    if (checked) {
      setSelectedRows((prevRows) => [...prevRows, rowName]);
      setChecked(true)
     
    } else {
      
      setChecked(false)
      setSelectedRows((prevRows) =>
        prevRows.filter((item) => item !== rowName)
      );
      console.log(rowName);
    }
  };
useEffect(()=>{
    console.log(selectedRows)
},[selectedRows])

//   select all the checkboxes

// need to modify, currently using dummy data
  const transformToTableData = () => {
    // TODO: Create Owner Image
    return dummyData?.map((each: any) => ({
      select: (
        <Checkbox
        onChange={(e) =>
            handleRowSelection(each.contract_title, e.currentTarget.checked)
          }
        />
      ),
      contract_title: (
        <Text
          textStyle="primary.link"
          m="auto"
          cursor="pointer"
        //   onClick={() => navigate(`/instantQuote/newQuote/${each.name}`)}
        >
          {each.contract_title}
        </Text>
      ),
      status: (
        <Tag
          width="100px"
          m="auto"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign="center"
          p="0.5em"
          bg = {each.status==="draft"? "#b0e0e6" : "#90ee90"}
          color = {each.status==="draft"? "#0087ee" : "008000"}
          fontWeight="bold"
         
        >
            <TagLabel ml="auto" mr="auto">
            {each.status}
            </TagLabel>
        </Tag>
      ),
      supplier: each.supplier,
      contractType: each.contractType,
      createdBy: each.supplier,
      createdOn: each.createdOn,
      
    }));
  };
  
//   remove this as well- dummy data
  useEffect(()=>{
    console.log(transformToTableData)
    setTableRowList(transformToTableData)
  },[])
  
  return (
    <Box width="100%">
     

      {/* Table */}
      <Box overflowY="scroll" mt="1rem">
          <GeneralTable
            tableHeaderList={tableHeaderList}
             tableRowList={tableRowList}
          />
        </Box>
    </Box>
  );
};
export default ContractsTable;
