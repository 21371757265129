import React from "react";
import { Box, Flex, Grid, GridItem, Img, Text } from "@chakra-ui/react";
import pdf from "../../img/pdf.svg";
import { FiDownload } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import Elements from "../../img/element.svg";
import { AiOutlineFileWord, AiOutlineFile } from "react-icons/ai";
interface uploadedCardProps {
  filename: any;
  size: any;
  deleteFunction: () => void;
  file_url?: any;
  downloadFunc: () => void;
}
export const UploadedCard = ({
  filename,
  size,
  deleteFunction,
  file_url,
  downloadFunc,
}: uploadedCardProps) => {
  const getFileIcon = (fileName) => {
    const fileExtension = fileName?.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return <Img src={pdf} height="30px" width="30px" />;
      case "doc":
        return <AiOutlineFileWord color="#0000FF" size="30px" />;
      case "docx":
        return <AiOutlineFileWord color="#0000FF" size="30px" />;
      default:
        return <AiOutlineFile color="#000" size="30px" />;
    }
  };
  return (
    <Box
      mt="1em"
      width={"100%"}
      height={"100%"}
      p="1em"
      border="1px dashed #0087ee"
      sx={{
        borderImage: "none",
        borderStyle: "dashed",
        borderWidth: "1px",
        borderColor: "#0087ee",
        borderRadius: "4px",
        borderDasharray: "5, 5",
      }}
    >
      <Flex flexDir="row" justifyContent="space-between">
        <Flex flexDir="row" gap="0.5em" alignItems="center">
          <Box>{getFileIcon(filename)}</Box>
          <Flex flexDir="column" gap="0.2em">
            <Text fontWeight={600} fontSize="16px" color="#0087ee">
              {filename}
            </Text>
            <Text
              color="rgba(181, 181, 195, 1)"
              fontWeight={500}
              fontSize="13px"
            >
              {size} Mb
            </Text>
            <Flex flexDir="row" alignItems="center" gap="0.2em">
              <Img
                src={Elements}
                width="15px"
                height="15px"
                alt="Upload completed"
              />
              <Text color="#0087ee" fontWeight={500} fontSize="13px">
                Upload completed
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap="0.5em">
          <Box
            h="29px"
            w="29px"
            bg="rgba(0, 135, 238, 0.05)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={downloadFunc}
          >
            <FiDownload color="#0087ee" />
          </Box>
          <Box
            h="29px"
            w="29px"
            bg="rgba(0, 135, 238, 0.05)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={deleteFunction}
          >
            <IoTrashOutline color="#0087ee" />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
