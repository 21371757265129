import {
  Box,
  Button,
  Divider,
  Flex,
  VStack,
  Img,
  Text,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import React, { useState } from "react";
import file from "../../img/file.png";
import paper from "../../img/paper.png";
import FileUploadAnimation from "../../img/FileUploadAnimation.gif";
import Elements from "../../img/element.svg";
import { UploadedCard } from "./UploadedCard";
import axios from "axios";
import { setPageLoading, AppDispatch, SET_MESSAGE, ERROR_MSG, formatErrorMessage, frappeMethodService } from "@karkhanaui/react";
import { useDispatch } from "react-redux";
interface NDAUploadCardProps {
  supplier_email :  any,
  setUploadData: (data: any) => void;
  supplierName :  any;
  setIsOpen : (data:boolean) => void;
  getUploadDocData : ()=> void;
  sId : any;
  fileId: any;
  setStatus : (data:any)=>void
}
export const UploadPopup = ({ supplier_email,setUploadData, supplierName, setIsOpen, getUploadDocData, sId, fileId , setStatus}:NDAUploadCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedOption, setSelectedOption] = useState<any>();
  const [steps, setSteps] = React.useState<any>(0);
  const [files, setFiles] = React.useState<File[]>([]);
  const [isLoading, setIsLoading] =  React.useState<boolean>(false)



  const uploadNDA =  async(file:any)=>{
    setIsLoading(true);
    const formData =  new FormData();
    formData.append("supplier_email", supplier_email);
    formData.append("status",  selectedOption == "signed_nda" ? "Signed" : "Pending");
    formData.append("nda_file",file[0])
    await axios.post('/api/method/procurement.api.nda.upload_nda',formData).then((res)=>{
      setIsLoading(false)
      setUploadData(res.data.message)
      setSteps(steps+1);

    }).catch((error)=>{
      setIsLoading(false)
      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: formatErrorMessage(error),
        },
      });
    })
  }
  // const handleSelectOption = (option: string) => {
  //   setSelectedOption(option === selectedOption ? option : null );
  // };
  function bytesToMegabytes(bytes) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }
  const [progress, setProgress] = useState(0);

  React.useEffect(() => {
    const uploadByteTransfer = async () => {
      
      const formData = new FormData();
      formData.append("file", files[0]);

      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setProgress(percentCompleted);
      };

      xhr.open("POST", "/upload", true);
      xhr.send(formData);
    };

    uploadByteTransfer();
  }, [files, steps]);
  


  const deleteFunction = async()=>{
    dispatch(
      setPageLoading({
        loading: true,
        loadingText: "Deleting file.....",
      })
    );
    await axios.post('/api/method/procurement.api.nda.remove_attachment_nda',{
      "payload": {
        "file_id": fileId,
        "docname": sId
    }
    }).then((res)=>{
     
      dispatch(
        setPageLoading({
          loading: false,
          loadingText: "",
        })
      );
     getUploadDocData();
     setIsOpen(false);
     setStatus("NA")
    }).catch((err)=>{
      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: formatErrorMessage(err),
        },
      });
    })
  }
  const onDrop = React.useCallback(
    (acceptedFiles: any, fileRejections: any) => {

      if (fileRejections.length > 0) {
        let isLargeFile = 0,
          fileNotSupported = 0;
        fileRejections.forEach((file: any) => {
          file.errors.forEach((err: any) => {
            if (err.code === "file-too-large") {
              isLargeFile++;
            }
            if (err.code === "file-invalid-type") {
              fileNotSupported++;
            }
          });
        });
      }
      if (acceptedFiles.length > 0) {
        setFiles(acceptedFiles);
        setSteps(2);
        
      }
    },
    [files, selectedOption]
  );
function popupDeleteFunc()
{
  setFiles([]);
  setIsOpen(false)
}

const handleDownloadClick = () => {
  if (files.length > 0) {
    const file = files[0]; // Assuming you want to download the first file in the array
    const fileUrl = URL.createObjectURL(file);
    const fileName = file.name || 'download.pdf'; // Use file name from file object or a default name

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);


    // Clean up the object URL
    URL.revokeObjectURL(fileUrl);
  }
};
  const {
    getRootProps,
    getInputProps,
    open,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    minSize: 10,
    maxSize: 52428799,
  });
 
  return (
    <Box borderRadius="md" width="100%" height="100%" p="1em">
      {steps === 0 ? (
        <Box p="1em">
          <Text
            lineHeight="24px"
            fontSize="16px"
            fontWeight={500}
            color="rgba(33, 33, 33, 0.5)"
          >
            Select one option:
          </Text>
          <VStack mt="1em" gap="1em">
            <Box
              border="1px dashed rgba(181, 181, 195, 1)"
              p="1em"
              width="100%"
              display="flex"
              flexDir="row"
              gap="0.5em"
              cursor="pointer"
              onClick={() => setSelectedOption("signed_nda")}
              _hover={{
                bg:
                  selectedOption === "signed_nda"
                    ? "rgba(241, 250, 255, 1)"
                    : "none",
              }}
              bg={
                selectedOption === "signed_nda"
                  ? "rgba(241, 250, 255, 1)"
                  : "f7f7f7"
              }
              borderRadius="md"
            >
              <Img src={paper} w="32px" h="32px" />
              <Flex flexDir="column" gap="0.2em">
                <Text
                  color="rgba(24, 28, 50, 1)"
                  fontWeight={600}
                  fontSize="18px"
                  lineHeight="21.33px"
                >
                  Signed NDA
                </Text>
                <Text
                  color="rgba(181, 181, 195, 1)"
                  fontSize="16px"
                  fontWeight={500}
                >
                  You are uploading an NDA that is already signed by this
                  supplier
                </Text>
              </Flex>
            </Box>

            <Box
              p="1em"
              border="1px dashed rgba(181, 181, 195, 1)"
              width="100%"
              display="flex"
              flexDir="row"
              gap="0.5em"
              cursor="pointer"
              onClick={() => setSelectedOption("new_nda")}
              _hover={{
                bg:
                  selectedOption === "new_nda"
                    ? "rgba(241, 250, 255, 1)"
                    : "none",
              }}
              bg={
                selectedOption === "new_nda" ? "rgba(241, 250, 255, 1)" : "none"
              }
              borderRadius="md"
            >
              <Img src={file} w="32px" h="32px" />
              <Flex flexDir="column" gap="0.2em">
                <Text
                  color="rgba(24, 28, 50, 1)"
                  fontWeight={600}
                  fontSize="18px"
                  lineHeight="21.33px"
                >
                  New NDA
                </Text>
                <Text
                  color="rgba(181, 181, 195, 1)"
                  fontSize="16px"
                  fontWeight={500}
                >
                  You are uploading a new NDA to be signed by this supplier
                </Text>
              </Flex>
            </Box>
          </VStack>

          <Divider mt="4rem" mb="1em" />
          <Box float="right">
            {selectedOption?.length > 0 && (
              <Button
                children="Next"
                bg="#0087ee"
                color="#fff"
                _hover={{ bg: "#0087ee80" }}
                disabled={!selectedOption}
                onClick={() => {
                  setSteps(1);
                }}
              />
            )}
          </Box>
        </Box>
      ) : steps === 1 ? (
        <Box
          mb="1em"
          p="1em"
          width={"100%"}
          height="100%"
          bg="rgba(0, 135, 238, 0.05)"
          borderRadius={"md"}
          border="1px dashed #0087ee"
          display="flex"
          flexDir={"column"}
          gap="0.5em"
          alignItems={"center"}
          justifyContent={"center"}
          {...getRootProps()}
        >
          <Img src={FileUploadAnimation} alt="file-upload-animation" />
          <Text onClick={open}>
            <span style={{ fontWeight: "500" }}>Drag and drop</span> files to
            upload, or
          </Text>
          <Button
            fontWeight={"600"}
            color="#fff"
            bgColor="#0087ee"
            _hover={{ bg: "#006DC0" }}
            onClick={open}
          >
            Upload NDA
          </Button>
          <input {...getInputProps({})} />
          <Text fontWeight="500">
            <b>File types</b>: PDF, Word | <b>File size</b>: &lt; 50 MB
          </Text>
          <Text color="#00000050" fontSize="12px">
            All your files are entirely secured with us{" "}
            <span style={{ color: "#0087ee" }}>
              (read our privacy guarantee)
            </span>
          </Text>
        </Box>
      ) : steps == 2 ? (
        <Box
         width={"100%"}
          height="100%"
        >

        <Box
          mb="1em"
          p="1em"
          width={"100%"}
          height="100%"
          bg="rgba(0, 135, 238, 0.05)"
          borderRadius={"md"}
          border="1px dashed #0087ee"
          display="flex"
          flexDir={"column"}
          gap="0.5em"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Img src={FileUploadAnimation} alt="file-upload-animation" />
          <Text fontWeight={600} fontSize={"16px"} lineHeight={"22px"}>
            {files[0]?.name}
          </Text>
          <Text
            fontSize={"16px"}
            lineHeight={"18.96px"}
            color="#0087ee"
            style={{
              fontWeight:
                Math.round(progress) >= 100 ? "bold" : "normal",
            }}
          >
            {Math.round(progress) >= 100 ? (
              <Flex flexDir={"row"} alignItems={"center"}  justifyContent={"center"} gap="0.2em">
                <Img
                  src={Elements}
                  width={"15px"}
                  height="15px"
                  alt="Upload completed"
                />
                Upload completed
              </Flex>
            ) : (
              `${Math.round(progress)}%`
            )}{" "}
            {Math.round(progress) < 100 && "uploading"} &nbsp;
            &nbsp; &nbsp;
            {Math.round(progress) < 100 &&
              `${bytesToMegabytes(files[0]?.size)} Mb`}
          </Text>
       
        </Box>
        {Math.round(progress) >= 100 && <Divider mt="3rem" mb="1em"/>}
          {Math.round(progress) >= 100 && (
            <Box float = "right">
            <Button
                children="Next"
                bg="#0087ee"
                color="#fff"
                _hover={{ bg: "#0087ee80" }}
                onClick={() => {
                 
                    setSteps(steps+1);
                 
                }}
              />
              
              </Box>)}
        </Box>
      ) : steps ==3  ? (
        <Box width="100%" height="100%">
            <Text fontSize={"18px"} color="rgba(33, 33, 33, 0.5)" lineHeight={"24px"} fontWeight={500}>
              {selectedOption == "new_nda" ?`Are you sure you want to share this NDA with the supplier- ${supplierName} . Once shared, you cannot delete it.` :
              `Are you sure you want to share this NDA?
Once shared, it cannot be deleted. Please ensure it is signed before proceeding.`}
              </Text>
            <Box mt="1em" mb="2rem">
                <UploadedCard downloadFunc={handleDownloadClick} file_url={files[0]?.name} deleteFunction={popupDeleteFunc} filename={files[0]?.name} size = {bytesToMegabytes(files[0]?.size)}/>
            </Box>
            <Divider mt="3rem" mb="1rem"/>
            <Box float =  "right">
            <Button
              isLoading = {isLoading}
                children="Next"
                bg="#0087ee"
                color="#fff"
                _hover={{ bg: "#0087ee80" }}
                onClick={() => {
                  uploadNDA(files)
                  getUploadDocData();
                  
                }}
              />
            </Box>

            </Box>
      ): steps == 4 ? (
        <Box width={"100%"} height="100%" display={"flex"} alignItems={"center"} justifyContent={"center"} flexDir={"column"}>
          <Box mt="4rem" display={"flex"} alignItems={"center"} justifyContent={"center"} flexDir={"column"}>
            <Text fontSize={"20px"} fontWeight={600} lineHeight={"23.7px"}>Your NDA has been shared with the Supplier- {supplierName}</Text>
            <Button children = "View NDA" color="#fff" bg="#0087ee"  _hover={{bg : "#0087ee80"}} mb="1em" mt="1rem" onClick={()=>{
              setIsOpen(false);
             

            }}/>
          </Box>
        </Box>
      ): null}
    </Box>
  );
};
