import mixpanel from 'mixpanel-browser';

// check environment
let env_check =  process.env.REACT_APP_NODE_ENV;
console.log({env_check})
if(env_check === 'development') 
{
  mixpanel.init('5c6d8cc401c308ef277a15961db1f79d',{track_pageview: true });
}
else if(env_check === "production")
{
  mixpanel.init("52c498feecf6655806ea7991601290f7",{track_pageview: true })
}
let actions = {
    identify: (id) => {
      if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
      if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
      if (env_check) mixpanel.track(name, props);
    },
    track_links: (id,name, props) => {
      if (env_check) mixpanel.track(id,name, props);
    },
    people: {
      set: (props) => {
        if (env_check) mixpanel.people.set(props);
      },
    },
    track_pageview : (email, id)=>{
      if(env_check) mixpanel.track_pageview(email,id)
    },
    track_forms : (id,name, props) =>{
      if(env_check) mixpanel.track_forms(id,name, props)
    }
  };
  
  export let Mixpanel = actions;
